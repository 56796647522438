import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, catchError, combineLatest, finalize, of, take, tap } from 'rxjs';
import { SelectedOrgService } from '../selected-org.service';
import { UserPreferencesService } from '../entities/user-preferences/user-preferences.service';
import { IUserPreferencesData } from '../../models/user-preference/user-preference.model';
import { AuthService } from '../auth.service';

interface IUserPreferencesState {
  preferences$: BehaviorSubject<Partial<IUserPreferencesData>>;
  inited$: BehaviorSubject<boolean>;

  fetching$: BehaviorSubject<boolean>;
  updating$: BehaviorSubject<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesStoreService implements OnDestroy {

  public state: IUserPreferencesState = {
    preferences$: new BehaviorSubject<Partial<IUserPreferencesData>>({}),
    inited$: new BehaviorSubject<boolean>(false),
  
    fetching$: new BehaviorSubject<boolean>(false),
    updating$: new BehaviorSubject<boolean>(false)
  }
  
  private initedUserUuid: string | null = null;
  private initedOrgId: number | null = null;
  
  subs: Subscription[] = [];

  constructor(
    private selectedOrg: SelectedOrgService,
    private userPreferencesService: UserPreferencesService,
    private authService: AuthService
  ) {
    this.subs.push(
      combineLatest([ this.authService.afUser$, this.selectedOrg.selectedOrgId$ ]).subscribe(([ afUser, orgId ]) => {
        if (!afUser || !orgId) return;
        if (
          afUser.uid !== this.initedUserUuid ||
          orgId !== this.initedOrgId
        ) {
          this.initedOrgId = orgId;
          this.initedUserUuid = afUser.uid;
          
          this.init();
        }
      }),

    );
  }

  init() {
    if (!this.initedUserUuid || !this.initedOrgId) return;

    this.state.fetching$.next(true);
    this.userPreferencesService.getForUser({
      userUuid: this.initedUserUuid,
      orgId: this.initedOrgId
    }).subscribe(
      res => {
        this.state.preferences$.next(res?.preferences ?? {});
        this.state.fetching$.next(false);
        this.state.inited$.next(true);
      },
      () => {
        this.state.fetching$.next(false);
    })
  }

  public upsert(data: Partial<IUserPreferencesData>) {
    if (!this.initedUserUuid || !this.initedOrgId) return;

    this.state.updating$.next(true);
    return this.userPreferencesService.upsert({
      userUuid: this.initedUserUuid,
      orgId: this.initedOrgId,
      preferences: data
    }).pipe(
      take(1),
      tap((r) => {
        this.state.preferences$.next(r.preferences);
        this.state.updating$.next(false);
      }),
      catchError(() => {
        this.state.updating$.next(false);
        return of(null);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
